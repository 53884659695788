import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger/src/";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { SYNC_STATE as SYNC_AUTH_STATE } from "common/reducers/authentication";
import { SYNC_STATE as SYNC_SETTINGS_STATE } from "common/reducers/settings";
// import { SYNC_STATE as SYNC_MAPPINGS_STATE } from "common/reducers/mappings";
import { listenForLocalStorageChanges } from "common/helpers/localStorageReducer";

import { reducers } from "./reducers";

const getMiddleware = () => {
    if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "test") {
        return applyMiddleware(thunk);
    }
    
    // Enable additional logging in non-production environments.
    return applyMiddleware(thunk, createLogger());
};

export const store = createStore(reducers, composeWithDevTools(getMiddleware()));

// Sync reducers state between tabs
// MRREF: add sync for mappings

listenForLocalStorageChanges({
    key: "authentication",
    reducerKey: "authentication",
    store,
    syncAction: SYNC_AUTH_STATE
});

listenForLocalStorageChanges({
    key: "settings_dev",
    reducerKey: "settings",
    store,
    syncAction: SYNC_SETTINGS_STATE
});
