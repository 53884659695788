import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    access_token: null,
    refresh_token: null,
    refresh_token_request_status: "idle",
    exp: null,
    username: null,
    user_ref: null,
    wp_rm: null,
    role: null,
    session_id: null,
    last_interacted: null, // flag to check if a user has recently interacted with a page
    auth_info: {}, // payload from `/user/sso?`
    url: null,
    sessionTimeoutModal: false,
    
    mimic_as_user: null,
    mimic_original_user: null,
    mimic_original_path: null
};

export const INTERACT_DEBOUNCE_WAIT_MS = 5000;

const decodeAccessToken = function(token) {
    const [, payload] = token.split(".");
    return JSON.parse(atob(payload));
};

const reducers = {
    AUTHENTICATE: (state, { payload }) => ({
        ...state,
        ...payload.access_token ? decodeAccessToken(payload.access_token) : {},
        ...payload
    }),
    
    LOGOUT: (state) => ({
        ...state,
        ...initialState
    }),
    
    // Clean up the store.
    // Also clear any mimicking stuff
    CLEAN_UP: (state) => ({
        ...state,
        ...initialState,
        auth_info: state.auth_info, // Remember auth_info
        url: state.url // Remember last url
    }),
    
    INTERACT: (state, { payload: { debounced = INTERACT_DEBOUNCE_WAIT_MS, type } = {} }) => ({
        ...state,
        last_interacted: Date.now() - debounced // We debounce INTERACT event and it fires after INTERACT_DEBOUNCE_WAIT_MS events stopped triggering
    }),
    
    STORE_AUTH_INFO: (state, action) => ({
        ...state,
        auth_info: action.payload || initialState.auth_info
    }),
    
    CLEAR_AUTH_INFO: (state) => ({
        ...state,
        auth_info: initialState.auth_info
    }),
    
    STORE_LAST_URL: (state, action) => ({
        ...state,
        url: action.payload
    }),
    
    CLEAR_LAST_URL: (state, action) => ({
        ...state,
        url: initialState.url
    }),
    
    SHOW_SESSION_TIMEOUT: (state) => ({
        ...state,
        sessionTimeoutModal: true
    }),
    
    HIDE_SESSION_TIMEOUT: (state) => ({
        ...state,
        sessionTimeoutModal: false
    }),
    
    MIMIC: (state, action) => ({
        ...state,
        mimic_as_user: action.payload.mimic_as_user,
        mimic_original_user: action.payload.mimic_original_user,
        mimic_original_path: action.payload.mimic_original_path
    }),
    
    STOP_MIMIC: (state) => ({
        ...state,
        mimic_as_user: initialState.mimic_as_user,
        mimic_original_user: initialState.mimic_original_user,
        mimic_original_path: initialState.mimic_original_path
    }),
    
    SYNC_STATE: (state, { payload: diff }) => ({
        ...state,
        ...diff
    })
};

export const authentication_slice = createSlice({
    name: "authentication",
    initialState,
    reducers
});

export const authentication = authentication_slice.reducer;
export const {
    AUTHENTICATE, INTERACT, CLEAN_UP, STORE_AUTH_INFO, CLEAR_AUTH_INFO,
    STORE_LAST_URL, CLEAR_LAST_URL, SHOW_SESSION_TIMEOUT, HIDE_SESSION_TIMEOUT,
    MIMIC, STOP_MIMIC, SYNC_STATE
} = authentication_slice.actions;
