import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    loaded: false,
    data: []
};

const reducers = {
    FETCH_REPORTS_START: (state, action) => ({ ...state, loading: true, loaded: false }),
    FETCH_REPORTS_SUCCESS: (state, action) => {
        const data = action.payload.filter((n) => !n.last_viewed);
        return {
            ...state, loaded: true, loading: false, data
        };
    },
    MARK_REPORT_START: (state, action) => ({ ...state, loading: true, loaded: false }),
    
    MARK_REPORT_SUCCESS: (state, action) => ({
        ...state,
        loaded: true,
        loading: false,
        data: state.data.map((d) => {
            if (d._id === action.payload) {
                return {
                    ...d,
                    last_viewed: true
                };
            }
            return { ...d };
        })
    }),
    MARK_ALL_REPORTS_READ_SUCCESS: (state, action) => ({
        ...state,
        loaded: true,
        loading: false,
        data: []
    })
    
};

export const report_notifications_Slice = createSlice({
    name: "report_notifications",
    initialState,
    reducers
});

export const {
    FETCH_REPORTS_START, FETCH_REPORTS_SUCCESS, MARK_REPORT_START, MARK_REPORT_SUCCESS, MARK_ALL_REPORTS_READ_SUCCESS
} = report_notifications_Slice.actions;
export const report_notifications = report_notifications_Slice.reducer;
