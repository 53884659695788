import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    qualifiers: ""
};

const reducers = {
    SET_STATE_FROM_URL: (state, action) => ({
        ...state,
        qualifiers: action.payload.qualifiers || state.qualifiers || initialState.qualifiers
    }),
    
    UPDATE_FILTERS: (state, action) => ({
        ...state,
        qualifiers: action.payload.qualifiers
    })
};

export const sessions_slice = createSlice({
    name: "sessions",
    initialState,
    reducers
});

export const sessions = sessions_slice.reducer;
export const { UPDATE_FILTERS, SET_STATE_FROM_URL } = sessions_slice.actions;
