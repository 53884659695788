const defaultComparisonState = {
    name: "",
    id: "",
    dates: "",
    datetype: "",
    hasLoadedPageData: false,
    navigateBack: null,
    qualifiers: "",
    segments: [],
    metrics: [],
    expanded_metric: null,
    active_tab: null,
    active_tab_structure: {},
    pivot_table_structures: {},
    widgets: {},
    comparison_configurator: {
        segments: [],
        metrics: [],
        qualifiers: "",
        index: void 0
    },
    original_report_data: {}
};

export function comparison(state = defaultComparisonState, action) {
    if (action.type === "RUN_COMPARISON_FROM_ANALYTICS" || action.type === "UPDATE_COMPARISON_FROM_URL") {
        const newState = {
            ...state,
            hasLoadedPageData: true,
            navigateBack: action.navigateBack || state.navigateBack, // Preserve navigateBack property as much as we can...
            qualifiers: action.qualifiers,
            segments: action.segments,
            metrics: action.metrics.sort((a, b) => { // Preserve sort based on existing metrics
                const indexA = state.metrics.indexOf(a);
                const indexB = state.metrics.indexOf(b);
    
                if (indexA === -1) {
                    return 999;
                }
    
                if (indexB === -1) {
                    return -999;
                }
    
                return indexA - indexB;
            }),
            active_tab: action.active_tab,
            active_tab_structure: action.active_tab_structure,
            expanded_metric: action.expanded_metric || defaultComparisonState.expanded_metric,
            comparison_configurator: defaultComparisonState.comparison_configurator,
            pivot_table_structures: defaultComparisonState.pivot_table_structures
        };
        
        if (newState.expanded_metric && newState.active_tab && Object.keys(newState.active_tab_structure).length) {
            newState.pivot_table_structures[`${newState.expanded_metric}_${newState.active_tab}`] = newState.active_tab_structure;
        }
        
        return newState;
    }
    
    if (action.type === "RESET_COMPARISON_STORE") {
        return {
            ...defaultComparisonState,
            navigateBack: state.navigateBack // Keep navigate back value in the state though
        };
    }
    
    if (action.type === "RUN_COMPARISON_FROM_REPORT") {
        return {
            ...state,
            hasLoadedPageData: true,
            
            name: action.name,
            id: action.id,
            dates: action.dates,
            datetype: action.datetype,
            qualifiers: action.qualifiers,
            segments: action.segments,
            metrics: action.metrics,
            expanded_metric: action.expanded_metric,
            pivot_table_structures: action.pivot_table_structures,
            
            // We should store original to check if report data changes
            original_report_data: {
                dates: action.original_report_data.dates,
                qualifiers: action.original_report_data.qualifiers,
                segments: action.original_report_data.segments,
                metrics: action.original_report_data.metrics,
                expanded_metric: action.original_report_data.expanded_metric,
                pivot_table_structures: action.original_report_data.pivot_table_structures
            },
    
            navigateBack: defaultComparisonState.navigateBack,
            comparison_configurator: defaultComparisonState.comparison_configurator
        };
    }
    
    if (action.type === "UPDATE_REPORT_ORIGINAL_DATA") {
        return {
            ...state,
            original_report_data: {
                dates: action.original_report_data.dates,
                qualifiers: action.original_report_data.qualifiers,
                segments: action.original_report_data.segments,
                metrics: action.original_report_data.metrics,
                expanded_metric: action.original_report_data.expanded_metric,
                pivot_table_structures: action.original_report_data.pivot_table_structures
            }
        };
    }
    
    if (action.type === "UPDATE_ACTIVE_TAB") {
        return {
            ...state,
            active_tab: action.tab,
            active_tab_structure: state.pivot_table_structures[`${state.expanded_metric}_${action.tab}`] || defaultComparisonState.active_tab_structure
        };
    }
    
    if (action.type === "REMOVE_METRIC_CARD") {
        return {
            ...state,
            metrics: state.metrics.filter((m) => m !== action.id)
        };
    }
    
    if (action.type === "EXPAND_METRIC_CARD") {
        return {
            ...state,
            expanded_metric: action.id
        };
    }
    
    if (action.type === "UPDATE_SEGMENTS") {
        return {
            ...state,
            segments: action.segments,
            comparison_configurator: defaultComparisonState.comparison_configurator
        };
    }
    
    if (action.type === "CONFIGURE_COMPARISON") {
        return {
            ...state,
            comparison_configurator: {
                segments: action.segments,
                metrics: action.metrics,
                qualifiers: action.qualifiers,
                index: action.index
            }
        };
    }
    
    if (action.type === "CONFIGURE_EXISTING_COMPARISON") {
        return {
            ...state,
            comparison_configurator: {
                segments: state.segments.filter((s, i) => i === action.index),
                metrics: state.metrics,
                qualifiers: state.qualifiers,
                index: action.index
            }
        };
    }
    
    if (action.type === "REMOVE_SEGMENT_FROM_CONFIGURATION") {
        return {
            ...state,
            comparison_configurator: {
                ...state.comparison_configurator,
                segments: state.comparison_configurator.segments.filter((m, i) => i !== action.index)
            }
        };
    }
    
    if (action.type === "UPDATE_SEGMENT_CONFIGURATION") {
        return {
            ...state,
            comparison_configurator: {
                ...state.comparison_configurator,
                segments: state.comparison_configurator.segments.map((segment, i) => {
                    if (i === action.index) {
                        const name = typeof action.name === "string" ? action.name : segment.name;
                        const filters = typeof action.filters === "string" ? action.filters : segment.filters;
                        
                        return {
                            name,
                            filters
                        };
                    }
                    
                    return { ...segment };
                })
            }
        };
    }
    
    if (action.type === "UPDATE_METRICS_CONFIGURATION") {
        return {
            ...state,
            comparison_configurator: {
                ...state.comparison_configurator,
                metrics: action.metrics
            }
        };
    }
    
    if (action.type === "ADD_EMPTY_SEGMENT_TO_CONFIGURATION") {
        return {
            ...state,
            comparison_configurator: {
                ...state.comparison_configurator,
                segments: state.comparison_configurator.segments.push({
                    filters: []
                })
            }
        };
    }
    
    if (action.type === "REMOVE_SEGMENT") {
        return {
            ...state,
            segments: state.segments.filter((m, i) => i !== action.index)
        };
    }
    
    if (action.type === "COMPARISON_UPDATE_FILTERS") {
        return {
            ...state,
            qualifiers: action.qualifiers
        };
    }
    
    if (action.type === "COLLAPSE_METRIC") {
        return {
            ...state,
            active_tab: defaultComparisonState.active_tab,
            active_tab_structure: defaultComparisonState.active_tab_structure,
            expanded_metric: defaultComparisonState.expanded_metric
        };
    }
    
    if (action.type === "UPDATE_PIVOT_TABLE_FIELDS") {
        return {
            ...state,
            active_tab_structure: action.data,
            pivot_table_structures: {
                ...state.pivot_table_structures,
                [action.hash]: action.data
            }
        };
    }
    
    if (action.type === "FETCH_WIDGET_START") {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                [action.hash]: {
                    status: "fetching",
                    data: []
                }
            }
        };
    }
    
    if (action.type === "FETCH_WIDGET_SUCCESS") {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                [action.hash]: {
                    status: "fetched",
                    data: action.data
                }
            }
        };
    }
    
    if (action.type === "FETCH_WIDGET_ERROR") {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                [action.hash]: {
                    status: "error"
                }
            }
        };
    }
    
    return state;
}
