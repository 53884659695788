export const defaultBenchmarksState = {
    name: "",
    id: "",
    dates: "",
    datetype: "",
    hasLoadedPageData: false,
    metrics_view: "grid",
    qualifiers: "",
    segments: [{
        name: "Your data",
        your: true, // A flag that defines that this segment bucket is your data. Benchmarks can have only one "your" group
        filters: ""
    }],
    merchantCountBySegment: {},
    metrics: [
        "5af0f36edcfa183102e8df7e", // approval_rate_count
        "5af0f36edcfa183102e8df81" // approval_rate_amount,
    ],
    expanded_metric: null,
    active_tab: null,
    active_tab_structure: {},
    pivot_table_structures: {},
    widgets: {},
    benchmarks_configurator: {
        segments: [],
        metrics: [],
        qualifiers: "",
        index: void 0
    },
    original_report_data: {}
};

export function benchmarks(state = defaultBenchmarksState, action) {
    if (action.type === "RUN_BENCHMARKS_FROM_ANALYTICS" || action.type === "UPDATE_BENCHMARKS_FROM_URL") {
        const newState = {
            ...state,
            hasLoadedPageData: true,
            qualifiers: action.qualifiers,
            segments: action.segments,
            metrics_view: action.metrics_view,
            metrics: action.metrics.sort((a, b) => { // Preserve sort based on existing metrics
                const indexA = state.metrics.indexOf(a);
                const indexB = state.metrics.indexOf(b);
        
                if (indexA === -1) {
                    return 999;
                }
        
                if (indexB === -1) {
                    return -999;
                }
        
                return indexA - indexB;
            }),
            active_tab: action.active_tab,
            active_tab_structure: action.active_tab_structure,
            expanded_metric: action.expanded_metric || defaultBenchmarksState.expanded_metric,
            benchmarks_configurator: defaultBenchmarksState.benchmarks_configurator,
            pivot_table_structures: defaultBenchmarksState.pivot_table_structures
        };
        
        if (newState.expanded_metric && newState.active_tab && Object.keys(newState.active_tab_structure).length) {
            newState.pivot_table_structures[`${newState.expanded_metric}_${newState.active_tab}`] = newState.active_tab_structure;
        }
        
        // Make sure that "Your data" segment is always in the segments
        if (!newState.segments.length) {
            newState.segments = [...defaultBenchmarksState.segments];
        }
    
        // We only start with 4 metrics in benchmarks phase 1
        if (!newState.metrics.length) {
            newState.metrics = [...defaultBenchmarksState.metrics];
        }
        
        return newState;
    }
    
    if (action.type === "PAGE_LOADED") {
        return {
            ...state,
            hasLoadedPageData: true
        };
    }
    
    if (action.type === "RESET_BENCHMARKS_STORE") {
        return {
            ...defaultBenchmarksState
        };
    }
    
    if (action.type === "RUN_BENCHMARKS_FROM_REPORT") {
        return {
            ...state,
            hasLoadedPageData: true,
            
            name: action.name,
            id: action.id,
            dates: action.dates,
            datetype: action.datetype,
            qualifiers: action.qualifiers,
            segments: action.segments,
            metrics: action.metrics,
            expanded_metric: action.expanded_metric,
            pivot_table_structures: action.pivot_table_structures,
            
            // We should store original to check if report data changes
            original_report_data: {
                dates: action.original_report_data.dates,
                qualifiers: action.original_report_data.qualifiers,
                segments: action.original_report_data.segments,
                metrics: action.original_report_data.metrics,
                expanded_metric: action.original_report_data.expanded_metric,
                pivot_table_structures: action.original_report_data.pivot_table_structures
            },
            benchmarks_configurator: defaultBenchmarksState.benchmarks_configurator
        };
    }
    
    if (action.type === "UPDATE_REPORT_ORIGINAL_DATA") {
        return {
            ...state,
            original_report_data: {
                dates: action.original_report_data.dates,
                qualifiers: action.original_report_data.qualifiers,
                segments: action.original_report_data.segments,
                metrics: action.original_report_data.metrics,
                expanded_metric: action.original_report_data.expanded_metric,
                pivot_table_structures: action.original_report_data.pivot_table_structures
            }
        };
    }
    
    if (action.type === "UPDATE_ACTIVE_TAB") {
        return {
            ...state,
            active_tab: action.tab,
            active_tab_structure: state.pivot_table_structures[`${state.expanded_metric}_${action.tab}`] || defaultBenchmarksState.active_tab_structure
        };
    }
    
    if (action.type === "REMOVE_METRIC_CARD") {
        return {
            ...state,
            metrics: state.metrics.filter((m) => m !== action.id)
        };
    }
    
    if (action.type === "EXPAND_METRIC_CARD") {
        return {
            ...state,
            expanded_metric: action.id
        };
    }
    
    if (action.type === "UPDATE_SEGMENTS") {
        return {
            ...state,
            segments: action.segments,
            benchmarks_configurator: defaultBenchmarksState.benchmarks_configurator
        };
    }
    
    if (action.type === "CONFIGURE_BENCHMARKS") {
        return {
            ...state,
            benchmarks_configurator: {
                segments: action.segments,
                metrics: action.metrics,
                qualifiers: action.qualifiers,
                index: action.index
            }
        };
    }
    
    if (action.type === "CONFIGURE_EXISTING_BENCHMARKS") {
        return {
            ...state,
            benchmarks_configurator: {
                segments: state.segments.filter((s, i) => i === action.index),
                metrics: state.metrics,
                qualifiers: state.qualifiers,
                index: action.index
            }
        };
    }
    
    if (action.type === "REMOVE_SEGMENT_FROM_CONFIGURATION") {
        return {
            ...state,
            benchmarks_configurator: {
                ...state.benchmarks_configurator,
                segments: state.benchmarks_configurator.segments.filter((m, i) => i !== action.index)
            }
        };
    }
    
    if (action.type === "SWITCH_VIEW_GRID") {
        return {
            ...state,
            metrics_view: "grid"
        };
    }
    
    if (action.type === "SWITCH_VIEW_TABLE") {
        return {
            ...state,
            metrics_view: "table"
        };
    }
    
    if (action.type === "UPDATE_SEGMENT_CONFIGURATION") {
        return {
            ...state,
            benchmarks_configurator: {
                ...state.benchmarks_configurator,
                segments: state.benchmarks_configurator.segments.map((segment, i) => {
                    if (i === action.index) {
                        const name = typeof action.name === "string" ? action.name : segment.name;
                        const filters = typeof action.filters === "string" ? action.filters : segment.filters;
                        
                        return {
                            name,
                            filters,
                            your: segment.your
                        };
                    }
                    
                    return { ...segment };
                })
            }
        };
    }
    
    if (action.type === "UPDATE_METRICS_CONFIGURATION") {
        return {
            ...state,
            benchmarks_configurator: {
                ...state.benchmarks_configurator,
                metrics: action.metrics
            }
        };
    }
    
    if (action.type === "ADD_EMPTY_SEGMENT_TO_CONFIGURATION") {
        return {
            ...state,
            benchmarks_configurator: {
                ...state.benchmarks_configurator,
                segments: state.benchmarks_configurator.segments.push({
                    filters: "",
                    your: false
                })
            }
        };
    }
    
    if (action.type === "REMOVE_SEGMENT") {
        return {
            ...state,
            segments: state.segments.filter((m, i) => i !== action.index)
        };
    }
    
    if (action.type === "ADD_SEGMENT") {
        return {
            ...state,
            segments: [...state.segments, action.segment]
        };
    }
    
    if (action.type === "BENCHMARK_UPDATE_FILTERS") {
        return {
            ...state,
            qualifiers: action.qualifiers
        };
    }
    
    if (action.type === "COLLAPSE_METRIC") {
        return {
            ...state,
            active_tab: defaultBenchmarksState.active_tab,
            active_tab_structure: defaultBenchmarksState.active_tab_structure,
            expanded_metric: defaultBenchmarksState.expanded_metric
        };
    }
    
    if (action.type === "UPDATE_PIVOT_TABLE_FIELDS") {
        return {
            ...state,
            active_tab_structure: action.data,
            pivot_table_structures: {
                ...state.pivot_table_structures,
                [action.hash]: action.data
            }
        };
    }
    
    if (action.type === "FETCH_WIDGET_START") {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                [action.hash]: {
                    status: "fetching",
                    data: []
                }
            }
        };
    }
    
    if (action.type === "FETCH_WIDGET_SUCCESS") {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                [action.hash]: {
                    status: "fetched",
                    data: action.data
                }
            }
        };
    }
    
    if (action.type === "FETCH_WIDGET_ERROR") {
        return {
            ...state,
            widgets: {
                ...state.widgets,
                [action.hash]: {
                    status: "error"
                }
            }
        };
    }
    
    if (action.type === "FETCH_MERCHANT_COUNT_START") {
        return {
            ...state,
            merchantCountBySegment: {
                ...state.merchantCountBySegment,
                [action.hash]: {
                    status: "loading"
                }
            }
        };
    }
    
    if (action.type === "FETCH_MERCHANT_COUNT_SUCCESS") {
        return {
            ...state,
            merchantCountBySegment: {
                ...state.merchantCountBySegment,
                [action.hash]: {
                    status: "success",
                    data: action.data
                }
            }
        };
    }
    
    if (action.type === "FETCH_MERCHANT_COUNT_ERROR") {
        return {
            ...state,
            merchantCountBySegment: {
                ...state.merchantCountBySegment,
                [action.hash]: {
                    status: "error"
                }
            }
        };
    }
    
    return state;
}
