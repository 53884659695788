const defaultState = {
    all_reports: {
        loading: false,
        error: false,
        data: []
    },
    tabbed_reports: {},
    options: {
        id: "none",
        tab: "none",
        page: 1,
        filter: "{}",
        filterBar: "",
        temp_filter: "{}",
        walk: [],
        itemId: null,
        lastItemId: null,
    
        selectedChildReportId: null,
        selectedChildren: []
    },
    users: {
        loading: false,
        error: false,
        data: null
    }
};

export function reports(state = defaultState, action) {
    if (action.type === "REPORTS_UPDATE_STATE_FROM_URL") {
        return {
            ...state,
            options: {
                ...state.options,
                id: action.id || defaultState.options.id,
                tab: action.tab || defaultState.options.tab,
                filter: action.parsedParams.filter || defaultState.options.filter,
                filterBar: action.parsedParams.filterBar || defaultState.options.filterBar,
                temp_filter: action.parsedParams.filter || defaultState.options.filter,
                walk: action.parsedParams.walk || defaultState.options.walk,
                itemId: action.parsedParams.itemId || defaultState.options.itemId
            }
        };
    }

    if (action.type === "SET_TAB_GROUP_METADATA") {
        return {
            ...state,
            tabbed_reports: {
                ...state.tabbed_reports,
                [action.tabGroup]: action.metadata
            }
        };
    }
    
    if (action.type === "UPDATE_ITEM_ID") {
        return {
            ...state,
            options: {
                ...state.options,
                itemId: action.itemId
            }
        };
    }
    
    if (action.type === "AVAILABLE_REPORTS_FETCH_START") {
        return {
            ...state,
            all_reports: {
                loading: true,
                loaded: false,
                error: false,
                data: []
            }
        };
    }
    
    if (action.type === "AVAILABLE_REPORTS_FETCH_SUCCESS") {
        return {
            ...state,
            all_reports: {
                loading: false,
                loaded: true,
                error: false,
                data: action.data
            }
        };
    }
    
    if (action.type === "AVAILABLE_REPORTS_FETCH_ERROR") {
        return {
            ...state,
            all_reports: {
                loading: false,
                loaded: true,
                error: true,
                data: []
            }
        };
    }
    
    if (action.type === "SET_ACTIVE_REPORT") {
        return {
            ...state,
            options: {
                ...state.options,
                id: action.id,
                tab: defaultState.options.tab,
                page: defaultState.options.page,
                walk: [...defaultState.options.walk],
                lastItemId: defaultState.options.lastItemId,
                itemId: defaultState.options.itemId
            }
        };
    }

    if (action.type === "SET_ACTIVE_REPORT_TAB") {
        return {
            ...state,
            options: {
                ...state.options,
                tab: action.tab,
                page: defaultState.options.page,
                walk: [...defaultState.options.walk],
                lastItemId: defaultState.options.lastItemId,
                itemId: defaultState.options.itemId
            }
        };
    }
    
    if (action.type === "REPORT_NEW_ITEM_WITH_CHILDREN") {
        return {
            ...state,
            options: {
                ...state.options,
                id: action.parentReportId,
                itemId: "new",
                selectedChildReportId: state.options.id,
                selectedChildren: action.selectedRows // We need to store these somewhere...
            }
        };
    }
    
    if (action.type === "REPORTS_ASSIGN_ITEMS_SUCCESS") {
        return {
            ...state,
            options: {
                ...state.options,
                selectedChildReportId: defaultState.options.selectedChildReportId,
                selectedChildren: defaultState.options.selectedChildren
            }
        };
    }
    
    if (action.type === "GET_ALL_PAZIEN_USERS_START") {
        return {
            ...state,
            users: {
                loading: true,
                error: false,
                data: null
            }
        };
    }
    
    if (action.type === "GET_ALL_PAZIEN_USERS_SUCCESS") {
        return {
            ...state,
            users: {
                loading: false,
                error: false,
                data: action.data
            }
        };
    }
    
    if (action.type === "GET_ALL_PAZIEN_USERS_ERROR") {
        return {
            ...state,
            users: {
                loading: false,
                error: true,
                data: null
            }
        };
    }
        
    return state;
}
