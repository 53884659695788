import { createSlice } from "@reduxjs/toolkit";

const reducers = {
    SET_RECON_STORE: (state, action) => ({
        ...state,
        ...action.payload
    }),
    RESET_RECON_STORE: (state, action) => ({
        ...initialState,
        ...action.payload
    }),
    SET_SORT_OPTIONS: (state, action) => ({
        ...state,
        sortOptions: {
            ...action.payload
        }
    }),
    SET_QUALIFIERS: (state, action) => ({
        ...state,
        qualifiers: action.payload
    })
};

const initialState = {
    view: "flat", // Variants: "pivot", "txns"
    section: "all", // variants: "settlements", "chargebacks", "fees", "misc", "all"
    transferStatus: "settled",
    qualifiers: "",
    sortOptions: {
        key: "settle_date",
        order: -1
    },
    filters: ""
};

export const reconciliationSlice = createSlice({
    name: "reconciliation",
    initialState,
    reducers
});

export const reconciliation = reconciliationSlice.reducer;
export const actions = reconciliationSlice.actions;
export const { SET_RECON_STORE, RESET_RECON_STORE, SET_SORT_OPTIONS, SET_QUALIFIERS } = reconciliationSlice.actions;
