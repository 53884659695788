import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    merchant_capabilities: {
        has_oct: false,
        has_accepted_stl_status: false
    },
    merchant_dimensions: {
        status: "idle",
        data: {
            provider: []
        }
    }
};

const reducers = {
    RESET_DIMESIONS_STATE: (state, action) => ({
        ...initialState
    }),
    SET_MERCHANT_DIMENSIONS: (state, action) => ({
        ...state,
        merchant_dimensions: {
            ...state.merchant_dimensions,
            status: "loaded",
            data: action.data
        },
        merchant_capabilities: {
            has_oct: !!action.data?.stl_type?.includes("OCT"),
            has_nap: !!action.data?.provider?.includes("nap"),
            has_accepted_stl_status: !!action.data?.provider?.includes("nap") // Redundant now but may be used later with more providers supporting "accepted"
        }
    }),
    LOADING_MERCHANT_DIMENSIONS: (state, action) => ({
        ...state,
        merchant_dimensions: {
            ...state.merchant_dimensions,
            status: "loading"
        }
    }),

    SYNC_STATE: (state, { payload: diff }) => ({
        ...state,
        ...diff
    })
};

export const dimensionsSlice = createSlice({
    name: "dimensions",
    initialState,
    reducers
});

export const dimensions = dimensionsSlice.reducer;
export const actions = dimensionsSlice.actions;
