import "core-js/stable";
import "regenerator-runtime/runtime";

import { startsWith } from "lodash-es";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

// Check if FormData is up to the spec in the browser (*cough* *cough* IE11)
import "formdata-polyfill";

// Check if browser supports ClassList on SVG elements
// https://github.com/Pomax/react-onclickoutside#ie-does-not-support-classlist-for-svg-elements
import "common/shims/svgClassListShim";

// Check if the the browser supports Closest method
import "common/shims/closest";

import { retry } from "common/helpers/retry";
import { store } from "common/store";
import { theme } from "common/themes/styled";
import { ToastContainer } from "extensions/toast";

try {
    Promise.config({
        cancellation: true,
        longStackTraces: false
    });
} catch (error) {
    window.console.error(error);
    window.console.warn("Enabling bluebird cancellation failed");
}

// Redirect to the new administrator route
const currentPage = window.location.pathname;

// Don't want to load the rest of the app if just checking API db statuses.
if (startsWith(currentPage, "/merchant/pazping")) {
    Promise.resolve()
        // eslint-disable-next-line unicorn/prefer-top-level-await
        .then(async() => {
            const { setConfig, healthcheck } = await import("common/common-init");
            await setConfig();
            const body = await healthcheck();
            document.body.innerHTML = body;
        });
} else {
    Promise.resolve()
        .then(async() => {
            const { setConfig, checkApiHealth } = await import("common/common-init");
            return setConfig().then(() => retry(checkApiHealth));
        })
    
        .then(async() => {
            const { checkBrowser } = await import("common/components/checkBrowser");
            return checkBrowser();
        })
    
        .then(async() => {
            const { MerchantApp } = await import(/* webpackChunkName: "merchantApp" */ "merchant/app");
            ReactDOM.render(<Provider store={store}><MerchantApp /><ToastContainer /></Provider>, document.getElementById("wrapper"));
        })
    // Global Error boundary for all errors thrown during the app init process
        // eslint-disable-next-line unicorn/prefer-top-level-await
        .catch(async(error) => {
            window.console.error(error);
            const { MaintenanceMode } = await import("common/helpers/errors");
            if (error instanceof MaintenanceMode) {
                const { MaintenancePage } = await import("common/components/maintenance");
                
                ReactDOM.render(<MaintenancePage />, document.getElementById("wrapper"));
            } else {
                const { ErrorView } = await import("common/components/error");
                ReactDOM.render(<ThemeProvider theme={theme}><ErrorView error={error} custom inline={false} /></ThemeProvider>, document.getElementById("wrapper"));
            }
        });
}
