/* global APP_VERSION */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    api_version: "",
    api: "/",
    pageTracking: true,
    debug: false,
    deniedAccessStatusCode: 0,
    defaultRouteMerchant: "dashboard",
    defaultRouteAdmin: "credentials",
    env: "",
    ready: false,
    helpEmail: "help@pazien.com",
    notificationFetchInterval: 20_000,
    worldpayMerchant: "5807bdd3bf7ce77b20816ad5",
    merchantsWithAlertsToggle: [
        "5a0c690b9c172bde2e490583", // BooHoo
        "59145edc9c172b55a0a01ed5" // Isagenix
    ],
    widgets_ref: "55e86c3c1d41c86cf0d1a7d3",
    sentry_key: "a57b6fda987e4896a563aca78d55de07",
    highcharts_export: "https://export.pazien.com/highcharts",
    mediaQueryBreakpoint: "bp-small",
    formatting: {
        money: {
            precision: 2,
            format: {
                pos: "<small>%s</small>%v",
                neg: "<small>%s</small>(%v)",
                zero: "<small>%s</small>%v"
            }
        }
    },
    analytics: {
        precision: {
            authorizations: 2,
            chargebacks: 2,
            fees: 2,
            refunds: 2,
            fraud: 2
        }
    },
    version: APP_VERSION || "",
    reconFilterBar: false
};

const reducers = {
    ACCESS_DENIED: (state, action) => ({
        ...state,
        deniedAccessStatusCode: action.code
    }),

    ACCESS_GRANTED: (state, action) => ({
        ...state,
        deniedAccessStatusCode: 0
    }),

    APP_READY: (state, action) => ({
        ...state,
        ready: true
    }),

    DISABLE_PAGE_TRACKING: (state, action) => ({
        ...state,
        pageTracking: false
    }),

    RESET_CONFIG_STORE: () => ({ ...initialState }),

    SET_API_VERSION: (state, action) => ({
        ...state,
        api_version: action.data
    }),

    SET_APP_CONFIG: (state, action) => ({
        ...state,
        api_host: action.overrideAPI || action.data.api_host || action.data.apiURL,
        debug: action.data.debug,
        env: action.data.env,
        highcharts_export: action.data.highcharts_export
    }),

    SET_MEDIA_QUERY_BREAKPOINT: (state, action) => ({
        ...state,
        mediaQueryBreakpoint: action.value
    })
};

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers
});

export const config = configSlice.reducer;
export const actions = configSlice.actions;
