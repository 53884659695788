import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user_permissions: {
        alerts_view: {},
        alerts_manage: {}
    },
    merchant_permissions: {}
};

const reducers = {
    LOAD_USER_MERCHANT_PERMISSIONS: (state, action) => ({
        ...state,
        user_permissions: {
            earliest_start_date: "2015-01-01",
            has_page_analytics: action.data.data_access_authorizations
                || action.data.data_access_chargebacks
                || action.data.data_access_fees
                || action.data.data_access_sales_refunds,
            has_page_cashflow: action.data.data_access_chargebacks
                && action.data.data_access_fees
                && action.data.data_access_sales_refunds,
            has_page_reports: action.data.feature_packaged_reports || action.data.has_saved_reports,
            has_fraud: action.data.data_access_all_fraud || action.data.data_access_all_fraud_lite,
            canLoadApp: [
                "has_bu_access",
                "has_provider_access",
                "has_data_access"
            ].every((p) => action.data[p]),
            alerts_view: {
                // Only Pro/Enterprise users can see all alerts. Lite users can only see chargeback alerts.
                // We still want to allow the Lite users to see alerts manage tabs to upsell.
                // In order to be able to see an alert you do need to have access to the data
                chargebacks: (action.data.feature_alerts_view || action.data.feature_chargeback_alerts_view) && action.data.data_access_chargebacks,
                authorizations: (action.data.feature_alerts_view || action.data.feature_chargeback_alerts_view) && action.data.data_access_authorizations,
                sales: (action.data.feature_alerts_view || action.data.feature_chargeback_alerts_view) && action.data.data_access_sales_refunds,
                refunds: (action.data.feature_alerts_view || action.data.feature_chargeback_alerts_view) && action.data.data_access_sales_refunds,
                fees: (action.data.feature_alerts_view || action.data.feature_chargeback_alerts_view) && action.data.data_access_fees
            },
            alerts_manage: {
                chargebacks: action.data.feature_alerts_manage || action.data.feature_chargeback_alerts_manage,
                authorizations: action.data.feature_alerts_manage,
                sales: action.data.feature_alerts_manage,
                refunds: action.data.feature_alerts_manage,
                fees: action.data.feature_alerts_manage
            },
            has_txn_tables2: true,
            feature_reconciliation_v3: false,
            feature_fee_reports: true,

            ...action.userTypePermissions,
            ...action.data
        }
    }),

    LOAD_MERCHANT_PERMISSIONS: (state, action) => ({
        ...state,
        merchant_permissions: action.data || []
    }),

    RESET_PERMISSIONS: () => ({
        ...initialState
    })
};

const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    reducers
});

export const permissions = permissionsSlice.reducer;
export const actions = permissionsSlice.actions;
export const { LOAD_USER_MERCHANT_PERMISSIONS, LOAD_MERCHANT_PERMISSIONS, RESET_PERMISSIONS } = permissionsSlice.actions;
