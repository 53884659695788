import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: "idle",
    data: []
};

const reducers = {
    FETCH_NOTIFICATIONS_START: (state) => ({ ...state, status: "loading" }),
    
    FETCH_NOTIFICATIONS_SUCCESS: (state, action) => ({
        ...state,
        status: "idle",
        data: action.payload
    })
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers
});

export const {
    FETCH_NOTIFICATIONS_START,
    FETCH_NOTIFICATIONS_SUCCESS
} = notificationsSlice.actions;

export const notifications = notificationsSlice.reducer;
