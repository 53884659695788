import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications: {
        loading: false,
        loaded: false,
        error: false,
        data: [],
        active_notification: null,
        inbox_unread_only: false
    },
    snapshots: {},
    active: "",
    loadingFromUrl: false
};

const reducers = {
    SNAPSHOT_SET_FROM_URL_PARAMS: (state, action) => ({
        ...state,
        active: action.snapshot_ref,
        loadingFromUrl: true
    }),

    NOTIFICATIONS_FETCH_START: (state, action) => ({
        ...state,
        notifications: {
            ...state.notifications,
            loading: true,
            loaded: false,
            error: false
        }
    }),

    NOTIFICATIONS_FETCH_SUCCESS: (state, action) => {
        const sortedData = action.data.filter((n) => n.active === true).sort((a, b) => (b.created > a.created ? 1 : -1));
        let activeSnap = "";
        let activeNotification = "";
        
        if (state.loadingFromUrl) {
            activeSnap = state.active;
            activeNotification = sortedData.find((n) => n.snapshot_ref === activeSnap)?._id;
        } else if (sortedData.length) {
            // Set to first notification/snapshot id by default depending on view
            if (state.notifications.inbox_unread_only) {
                const firstUnread = sortedData.find((n) => n.read === false);
                activeSnap = firstUnread.snapshot_ref;
                activeNotification = firstUnread._id;
            } else {
                activeSnap = sortedData[0].snapshot_ref;
                activeNotification = sortedData[0]._id;
            }
        }
        
        return {
            ...state,
            notifications: {
                ...state.notifications,
                loading: false,
                loaded: true,
                error: false,
                data: sortedData,
                active_notification: activeNotification
            },
            active: activeSnap
        };
    },

    NOTIFICATIONS_FETCH_ERROR: (state, action) => ({
        ...state,
        notifications: {
            ...state.notifications,
            loading: false,
            loaded: true,
            error: true
        }
    }),

    NOTIFICATION_DELETE_SUCCESS: (state, action) => {
        const changedIndex = state.notifications.data.findIndex((n) => n._id === action.data);
        const newNotificationsData = state.notifications.data.filter((n) => n._id !== action.data);
        let nextActiveNotification = {};
        
        if (newNotificationsData.length > changedIndex) {
            nextActiveNotification = newNotificationsData[changedIndex];
        } else if (newNotificationsData.length) {
            nextActiveNotification = newNotificationsData[changedIndex - 1];
        }
        return {
            ...state,
            notifications: {
                ...state.notifications,
                data: newNotificationsData,
                loading: false,
                loaded: true,
                error: false,
                active_notification: nextActiveNotification?._id
            },
            active: nextActiveNotification?.snapshot_ref
            
        };
    },

    NOTIFICATIONS_POLL_SUCCESS: (state, action) => {
        if (action.data.length) {
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: false,
                    loaded: true,
                    error: false,
                    data: [
                        ...state.notifications.data,
                        ...action.data
                    ].sort((a, b) => (b.created > a.created ? 1 : -1))
                }
            };
        }
        return state;
    },

    NOTIFICATION_SET_ACTIVE: (state, action) => ({
        ...state,
        notifications: {
            ...state.notifications,
            active_notification: action.data
        },
        active: action.snap
    }),

    NOTIFICATION_UPDATE_SUCCESS: (state, action) => {
        if (action.data === "all") {
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loading: false,
                    loaded: true,
                    error: false,
                    data: state.notifications.data.map((n) => ({
                        ...n,
                        read: true
                    }))
                }
            };
        }
        
        const changedIndex = state.notifications.data.findIndex((n) => n._id === action.data);
        
        return {
            ...state,
            notifications: {
                ...state.notifications,
                data: state.notifications.data.map((n, index) => {
                    if (index === changedIndex) {
                        return {
                            ...n,
                            read: true
                        };
                    }
                    return n;
                }),
                loading: false,
                loaded: true,
                error: false
            }
        };
    },

    SET_INBOX_VIEW: (state, action) => {
        let activeSnap;
        let activeNotification;
        if (state.notifications.data.length) {
            // Set to first notification/snapshot id by default depending on view
            let snap;
            if (action.unreadOnly) {
                snap = state.notifications.data.find((n) => n.read === false);
            } else {
                snap = state.notifications.data.find((n) => n.read);
            }
            if (snap) {
                activeSnap = snap.snapshot_ref;
                activeNotification = snap._id;
            }
        }
        return {
            ...state,
            notifications: {
                ...state.notifications,
                inbox_unread_only: action.unreadOnly,
                active_notification: activeNotification
            },
            active: activeSnap
        };
    }
};

const snapshotsSlice = createSlice({
    name: "snapshots",
    initialState,
    reducers
});

export const snapshots = snapshotsSlice.reducer;
export const actions = snapshotsSlice.actions;
