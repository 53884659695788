import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    packaged_reports: {
        subscriptions: [],
        templates: [],
        error: null,
        data: []
    },
    saved_reports: {
        status: "idle",
        error: null,
        data: []
    }
};

const merchantReportsSlice = createSlice({
    name: "merchant_reports",
    initialState,
    reducers: {
        EDIT_SUBSCRIPTION: (state, action) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                subscriptions: state.packaged_reports.subscriptions.map((s) => {
                    if (action.payload.subscription_ref === s._id) {
                        return {
                            ...s,
                            ...action.payload.data
                        };
                    }
                    return s;
                })
            }
        }),
        EDIT_TEMPLATE: (state, action) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                templates: state.packaged_reports.templates.map((s) => {
                    if (action.payload.template_ref === s._id) {
                        return {
                            ...s,
                            ...action.payload.data
                        };
                    }
                    return s;
                })
            }
        }),
        FETCH_PACKAGED_REPORTS_SUBSCRIPTIONS: (state, { payload }) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                subscriptions: payload.data || state.packaged_reports.subscriptions,
                error: payload.error || state.packaged_reports.subscriptions
            }
        }),
        FETCH_PACKAGED_REPORTS_SUCCESS: (state, action) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                error: initialState.packaged_reports.error,
                data: action.payload
            }
        }),
        FETCH_PACKAGED_REPORTS_ERROR: (state, action) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                error: action.payload,
                data: initialState.packaged_reports.data
            }
        }),
        FETCH_SAVED_REPORTS_START: (state, action) => ({
            ...state,
            saved_reports: {
                ...state.saved_reports,
                status: "loading",
                data: initialState.saved_reports.data,
                error: initialState.saved_reports.error
            }
        }),
        FETCH_SAVED_REPORTS_SUCCESS: (state, action) => ({
            ...state,
            saved_reports: {
                ...state.saved_reports,
                status: "loaded",
                error: initialState.saved_reports.error,
                data: action.payload
            }
        }),
        FETCH_TEMPLATES_SUCCESS: (state, action) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                templates: action.payload
            }
        }),
        FETCH_SAVED_REPORTS_ERROR: (state, action) => ({
            ...state,
            saved_reports: {
                ...state.saved_reports,
                status: "error",
                error: action.payload,
                data: initialState.saved_reports.data
            }
        }),
        DELETE_SAVED_REPORTS_SUCCESS: (state, action) => ({
            ...state,
            saved_reports: {
                ...state.saved_reports,
                data: state.saved_reports.data.filter(({ _id }) => _id !== action.payload)
            }
        }),
        DELETE_SUBSCRIPTION_SUCCESS: (state, action) => ({
            ...state,
            packaged_reports: {
                ...state.packaged_reports,
                subscriptions: state.packaged_reports.subscriptions.filter(({ _id }) => _id !== action.payload)
            }
        }),
        RESET_SLICE: (state, action) => ({
            ...initialState
        })
    }
});

export const merchant_reports = merchantReportsSlice.reducer;
export const {
    EDIT_SUBSCRIPTION,
    FETCH_PACKAGED_REPORTS_SUBSCRIPTIONS,
    FETCH_PACKAGED_REPORTS_SUCCESS,
    FETCH_PACKAGED_REPORTS_ERROR,
    FETCH_SAVED_REPORTS_START,
    FETCH_SAVED_REPORTS_SUCCESS,
    FETCH_SAVED_REPORTS_ERROR,
    DELETE_SAVED_REPORTS_SUCCESS,
    DELETE_SUBSCRIPTION_SUCCESS,
    RESET_SLICE,
    FETCH_TEMPLATES_SUCCESS,
    EDIT_TEMPLATE
} = merchantReportsSlice.actions;
