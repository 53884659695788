import { captureBreadcrumb } from "extensions/sentry";

export const retry = function(runFunc, delay = 1000, tries = 3) {
    return runFunc().catch((error) => { // If it fails
        // eslint-disable-next-line promise/no-return-wrap
        if (error.name === "AbortError") return Promise.resolve({});
        
        if (tries === 1) {
            captureBreadcrumb({
                message: "Exceed number of retries",
                category: "request",
                data: {
                    request: error.requestOptions,
                    url: error.response?.url
                }
            });
            
            throw error; // Give up and throw an error
        } else {
            captureBreadcrumb({
                message: `Still ${tries} retries to go. Scheduling another one to run in ${delay * 2}ms`,
                category: "request",
                data: {
                    request: error.requestOptions,
                    url: error.response?.url
                }
            });
            
            return Promise.delay(delay) // Delay and retry with more time
                .then(retry.bind(null, runFunc, delay * 2, tries - 1));
        }
    });
};
