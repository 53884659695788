export const defaultState = {
    qualifiers: "",
    section: "user_email",
    dimensions: null
};

export function emails(state = defaultState, action) {
    if (action.type === "SET_EMAIL_STORE") {
        return { ...state, ...action.data };
    }

    if (action.type === "EMAILS_UPDATE_FILTERS") {
        return {
            ...state,
            qualifiers: action.qualifiers
        };
    }

    return state;
}
