import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    "searchbar:mode": "token",
    alerts_version: 2,
    default_currency: "",
    start_page: "dashboard"
};

const reducers = {
    SET_SETTINGS: (state, action) => ({ ...state, ...action.payload })
};

export const user_merchant_settings_Slice = createSlice({
    name: "user_merchant_settings",
    initialState,
    reducers
});

export const user_merchant_settings = user_merchant_settings_Slice.reducer;
export const { SET_SETTINGS } = user_merchant_settings_Slice.actions;
