import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    loaded: false,
    downloads_data: [],

    // export dialog
    showExportDialog: false,
    // TODO: remove exporter, find a better solution to open a export dialog
    exportDialogConfiguration: {
        dialogTitle: "export",
        reducers: {},
        initialState: {},
        exporter: () => {}
    },
    automatic_download: []
};

const reducers = {
    FETCH_DOWNLOADS_START: (state, action) => ({ ...state, loading: true, loaded: false }),
    
    FETCH_DOWNLOADS_SUCCESS: (state, action) => ({
        ...state,
        loaded: true,
        loading: false,
        downloads_data: action.payload.filter((n) => n.type === "report_export")
    }),
    MARK_DOWNLOAD_START: (state, action) => ({
        ...state,
        loading: true,
        loaded: false
    }),
    
    MARK_DOWNLOAD_SUCCESS: (state, action) => ({
        ...state,
        loaded: true,
        loading: false,
        downloads_data: state.downloads_data.map((d) => {
            if (d._id === action.payload) {
                return {
                    ...d,
                    acknowledged: true
                };
            }
            return { ...d };
        })
    }),
    
    MARK_ALL_DOWNLOADS_READ_SUCCESS: (state, action) => ({
        ...state,
        loaded: true,
        loading: false,
        downloads_data: []
    }),
    
    DOWNLOADS_PANEL_OPEN: (state, action) => ({ ...state, forceOpenPanel: true }),

    // Use the actions defined in common/actions/downloads instead of these.
    SHOW_EXPORT_DIALOG: (state, action) => ({ ...state, showExportDialog: action.payload }),
    SET_EXPORT_DIALOG_CONFIGURATION: (state, action) => ({ ...state, exportDialogConfiguration: action.payload }),

    SCHEDULE_AUTOMATIC_DOWNLOAD: (state, action) => {
        const job_refs = Array.isArray(action.payload) ? action.payload : [action.payload];
        return {
            ...state,
            automatic_download: [...state.automatic_download, ...job_refs]
        };
    },

    CLEAR_AUTOMATIC_DOWNLOAD: (state, action) => ({
        ...state,
        automatic_download: state.automatic_download.filter((ref) => ref !== action.payload)
    })
};

export const downloads_Slice = createSlice({
    name: "downloads",
    initialState,
    reducers
});

export const {
    FETCH_DOWNLOADS_START,
    FETCH_DOWNLOADS_SUCCESS,
    MARK_DOWNLOAD_START,
    MARK_DOWNLOAD_SUCCESS,
    MARK_ALL_DOWNLOADS_READ_SUCCESS,
    SHOW_EXPORT_DIALOG,
    SET_EXPORT_DIALOG_CONFIGURATION,
    SCHEDULE_AUTOMATIC_DOWNLOAD,
    CLEAR_AUTOMATIC_DOWNLOAD
} = downloads_Slice.actions;
export const downloads = downloads_Slice.reducer;
