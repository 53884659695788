import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    table_configs: {
        transactions: {
            user: {},
            merchant: {},
            pazien: {}
        },
        recon_summary: {
            user: {},
            merchant: {},
            pazien: {}
        }
    }
};

const reducers = {
    SET_TABLE_CONFIGS:
        (state, action) => {
            /**
             * Add section level to configs object
             * We get component_name > pazien|user > [list of configs] from /ui_table_config
             * @param {Object} configs_obj "pazien|user > [list of configs]" portion
             * @returns {Object} component_name > pazien|user > section > config(object), i.e. transactions > pazien > authorizations > config
             */
            const configsTransformFn = (configs_obj) => Object.entries(configs_obj).reduce((memo, [level, items]) => ({
                ...memo,
                [level]: items.reduce((memo2, item) => ({
                    ...memo2,
                    [item.section]: item
                }), Object.create(null))
            }), Object.create(null));
            
            return {
                ...state,
                table_configs: Object.fromEntries(Object.entries(action.payload).map(([group_name, group_value]) => [group_name, configsTransformFn(group_value)]))
            };
        },
    UPDATE_STORED_CONFIG:
        (state, action) => ({
            ...state,
            table_configs: {
                ...state.table_configs,
                [action.payload.component]: {
                    ...state.table_configs[action.payload.component],
                    [action.payload.level]: {
                        ...state.table_configs[action.payload.level],
                        [action.payload.section]: action.payload
                    }
                }
            }
        }),
    
    RESET_TABLE_CONFIGS: () => ({ ...initialState })
};

export const componentsSlice = createSlice({
    name: "components",
    initialState,
    reducers
});

export const components = componentsSlice.reducer;
export const actions = componentsSlice.actions;
export const { SET_TABLE_CONFIGS, UPDATE_STORED_CONFIG } = componentsSlice.actions;
