import CryptoJS from "crypto-js";

const DELIMITER = "-";

const formatter = {
    stringify(cipherParams) {
        // Create json object with ciphertext
        const cipheredChunksArray = [cipherParams.ciphertext.toString(CryptoJS.enc.Base64)];
        
        // Add iv and salt
        cipheredChunksArray.push(
            cipherParams.iv.toString(),
            cipherParams.salt.toString()
        );
        
        return cipheredChunksArray.join(DELIMITER);
    },
    
    parse(cipheredChunks) {
        // Parse json string
        const cipheredChunksArray = cipheredChunks.split(DELIMITER);

        // Extract ciphertext from json object, and create cipher params object
        return CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(cipheredChunksArray[0]),
            iv: CryptoJS.enc.Hex.parse(cipheredChunksArray[1]),
            salt: CryptoJS.enc.Hex.parse(cipheredChunksArray[2])
        });
    }
};

const getKey = function() {
    let salt = localStorage.getItem("slt");
    if (!salt) {
        // Creates a word array filled with random bytes.
        salt = CryptoJS.lib.WordArray.random(16);
        localStorage.setItem("slt", salt);
    }
    return salt;
};

export const Crypt = {
    cipher(value = "", key = getKey()) {
        const encrypted = CryptoJS.AES.encrypt(value, key.toString(), { format: formatter });
        return encrypted.toString();
    },
    decipher(encrypted = "", key = getKey()) {
        const decrypted = CryptoJS.AES.decrypt(encrypted, key.toString(), { format: formatter });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
};
