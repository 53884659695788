import { orderBy } from "lodash-es";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loaded: false,
    filters: []
};

const orderFilters = (data) => orderBy(data, ["modified"], ["desc"]);

const reducers = {
    FILTERS_FETCH_SUCCESS: (state, action) => ({
        ...state,
        filters: orderFilters(action.data),
        loaded: true
    }),

    FILTERS_DELETE_SUCCESS: (state, action) => ({
        ...state,
        filters: state.filters.filter((f) => f._id !== action.data)
    }),

    FILTERS_SAVE_SUCCESS: (state, action) => ({
        ...state,
        filters: orderFilters([
            ...state.filters,
            action.data
        ]),
        loaded: true
    }),

    FILTERS_UPDATE_SUCCESS: (state, action) => ({
        ...state,
        filters: state.filters.map((filter) => {
            if (filter._id === action.data._id) {
                return action.data;
            }
            
            return filter;
        }),
        loaded: true
    })
};

export const filtersSlice = createSlice({
    name: "filters",
    initialState,
    reducers
});

export const filters = filtersSlice.reducer;
export const actions = filtersSlice.actions;
