import { omit } from "lodash-es";
import { combineReducers } from "redux";

import { config } from "common/reducers/config";
import { common } from "common/reducers/common";
import { comparison } from "common/reducers/comparison";
import { components } from "common/reducers/components";
import { benchmarks } from "common/reducers/benchmarks";
import { dataAvailability } from "common/reducers/dataAvailability";
import { reports } from "common/reducers/reports";
import { requests } from "common/reducers/requests";
import { reconciliation } from "common/reducers/reconciliation";
import { alerts } from "common/reducers/alerts";
import { snapshots } from "common/reducers/snapshots";
import { settings } from "common/reducers/settings";
import { user_merchant_settings } from "common/reducers/user_merchant_settings";
import { sessions } from "common/reducers/sessions";
import { merchant_reports } from "common/reducers/merchant_reports";
import { filters } from "common/reducers/filterBar";
import { authentication } from "common/reducers/authentication";
import { emails } from "common/reducers/email";
import { downloads } from "common/reducers/downloads";
import { report_notifications } from "common/reducers/reports_notifications";
import { analytics_display } from "common/reducers/analytics_display";
import { analytics } from "common/reducers/analytics";
import { permissions } from "common/reducers/permissions";
import { mappings } from "common/reducers/mappings";
import { dimensions } from "common/reducers/dimensions";
import { notifications } from "common/reducers/notifications";
import { createLocalStorageReducer } from "common/helpers/localStorageReducer";

// Create one app reducer
const appReducer = combineReducers({
    authentication: createLocalStorageReducer({
        reducer: authentication,
        key: "authentication",
        deserialize(data) {
            return omit(JSON.parse(data), ["sessionTimeoutModal"]);
        }
    }),
    config,
    common,
    comparison,
    components,
    benchmarks,
    dataAvailability,
    reports,
    merchant_reports,
    requests,
    alerts,
    snapshots,
    sessions,
    filters,
    settings: createLocalStorageReducer({
        reducer: settings,
        key: "settings_dev",
        persistWhen: (storedData) => !localStorage.getItem("mimic") || storedData?.persist_settings // TODO: Check on mimic handling here
    }),
    user_merchant_settings: createLocalStorageReducer({
        reducer: user_merchant_settings,
        key: "user_merchant_settings"
    }),
    reconciliation,
    emails,
    downloads,
    report_notifications,
    analytics,
    analytics_display: createLocalStorageReducer({
        reducer: analytics_display,
        key: "analytics_display",
        deserialize(data) {
            return omit(JSON.parse(data), ["sessionTimeoutModal"]);
        }
    }),
    permissions,
    mappings,
    dimensions,
    notifications
});

// Export root reducer with RESET_STATE action.
// Undefined state value is handled by default argument in each reducer
export function reducers(state, action) {
    if (action.type === "RESET_STATE") {
        state = void 0;
    }
    
    return appReducer(state, action);
}
