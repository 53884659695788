import { transform, isEqual } from "lodash-es";

import { Crypt } from "common/components/crypt";

export const getStoredData = ({
    storage = window.localStorage,
    key,
    deserialize,
    persistWhen = () => true
}) => {
    // try load from local storage
    let stored = storage.getItem(key);
    try {
        stored = Crypt.decipher(stored);
    } catch {
        stored = null;
    }

    let storedData = {};
    try {
        if (typeof stored === "string") {
            storedData = deserialize(stored);
        }
    } catch (error) {
        console.error(error);
    }

    const shouldPersist = persistWhen(storedData);

    return {
        storedData,
        shouldPersist
    };
};

/**
 * Shallow diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base, { ignore = new Set() } = {}) {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[key]) && !ignore.has(key)) {
            result[key] = value;
        }
    });
}

export const listenForLocalStorageChanges = ({
    key,
    reducerKey,
    store,
    syncAction
}) => {
    // Add event to listen for storage changes.
    // We don't really need to cleanit up because it's currently used only from store.js
    // Do not sync mimicking props since we should allow admin app
    // to remain unmimicked while the merchant app can mimic another user
    const ignore = new Set(["mimic_as_user", "mimic_original_path", "mimic_original_user"]);
    window.addEventListener("storage", () => {
        const { storedData } = getStoredData({
            key,
            deserialize: JSON.parse
        });

        const currentState = store.getState()[reducerKey];

        // Return earlier if received an empty object from the storage
        if (!Object.keys(storedData).length) return;

        const diff = difference(storedData, currentState, { ignore });
        if (Object.keys(diff).length) {
            store.dispatch(syncAction(diff));
        }
    });
};

export const createLocalStorageReducer = ({
    reducer,
    key,
    serialize = JSON.stringify,
    deserialize = JSON.parse,
    storage = window.localStorage,
    persistWhen = (storedData) => true
}) => {
    if (typeof storage !== "object" || storage === null) {
        return reducer;
    }

    return (prevState, action) => {
        const { storedData, shouldPersist } = getStoredData({
            storage,
            key,
            deserialize,
            persistWhen
        });

        if (typeof prevState === "undefined") {
            prevState = reducer(void 0, {});
        }

        prevState = {
            ...prevState,
            ...shouldPersist && storedData
        };

        const newState = reducer(prevState, action);

        if (prevState !== newState && shouldPersist) {
            storage.setItem(key, Crypt.cipher(serialize(newState)));
        }

        return newState;
    };
};
