import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mappings_fetched: false,
    mappings: {
        getters: [],
        avs_response_codes: {},
        packages: [],
        countries: {},
        mcc: {},
        payment_methods: {},
        cbk_lifecycle: {},
        cvv_response_codes: {},
        fraud_response_codes: {},
        currencies: {},
        fraud_program: {},
        cbk_codes: {},
        decline_codes: {},
        digital_wallet: {}
    }
};

const reducers = {
    RESET_MAPPINGS: (state, action) => ({
        ...initialState
    }),

    SET_ZOOLANDER_ALL: (state, action) => ({
        ...state,
        mappings_fetched: true,
        mappings: action.mappings
    }),

    SYNC_STATE: (state, { payload: diff }) => ({
        ...state,
        ...diff
    })
};

export const mappingsSlice = createSlice({
    name: "mappings",
    initialState,
    reducers
});

export const mappings = mappingsSlice.reducer;
export const actions = mappingsSlice.actions;
