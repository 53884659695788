const defaultState = {
    name: "Pazien",
    historyStack: [],
    currency: "USD",
    currency_decimals: 2,
    currency_symbol: "$",
    currency_source: "default",
    currency_seen: [], // Keep track of all currencies seen for the merchant
    has_unsaved_changes: false,
    merchant_users: {},
    merchant_groups: [],
    user_merchant_config: {}, // Merchant config of an active user
    merchant_config: {},
    all_merchants: [],
    user: {
        optIns: {},
        prerequisites: "idle"
    },
    merchant_ref: "",
    bu_ref: "",
    compare_to: [],
    date_range: {
        startDate: "",
        endDate: "",
        datetype: ""
    },
    filterBarData: {
        all: null,
        builtForBU: null,
        custom: {}
    },
    admins: {
        loading: false,
        error: false,
        data: null
    },
    product: "",
    pageQualifierType: "unset",
    route: "",
    pageName: "",
    qualifiersStr: "",
    users_needing_permissions: [],
    collapsedSidebar: false
};

export function common(state = defaultState, action) {
    if (action.type === "SET_COLLAPSED_SIDEBAR") {
        return {
            ...state,
            collapsedSidebar: action.payload
        };
    }

    if (action.type === "RESET_GLOBAL_DATE_RANGE") {
        return {
            ...state,
            compare_to: defaultState.compare_to,
            date_range: {
                ...defaultState.date_range
            }
        };
    }

    if (action.type === "SET_GLOBAL_DATE_RANGE_ONLY") {
        return {
            ...state,
            date_range: {
                ...state.date_range,
                startDate: action.payload.startDate.toISOString(),
                endDate: action.payload.endDate.toISOString()
            }
        };
    }

    if (action.type === "SET_DATE_RANGE_REACT") {
        const { dateType: datetype, endDate, startDate } = action.payload;
        
        return {
            ...state,
            date_range: {
                ...state.date_range,
                datetype,
                endDate,
                startDate
            }
        };
    }

    if (action.type === "SET_COMPARISONS_REACT") {
        return {
            ...state,
            compare_to: action.payload.additionalDateRanges
        };
    }

    if (action.type === "SET_GLOBAL_DATE_RANGE_ONLY_SANS_MOMENT") {
        return {
            ...state,
            date_range: {
                ...state.date_range,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            }
        };
    }

    if (action.type === "SET_GLOBAL_DATETYPE") {
        return {
            ...state,
            date_range: {
                ...state.date_range,
                datetype: action.datetype
            }
        };
    }

    if (action.type === "SET_GLOBAL_DATE_RANGE") {
        return {
            ...state,
            compare_to: Array.isArray(action.payload.compare_to) ? action.payload.compare_to.map(({ startDate, endDate }) => ({
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD")
            })) : state.compare_to,
            date_range: {
                ...state.date_range,
                startDate: action.payload.startDate.toISOString(),
                endDate: action.payload.endDate.toISOString(),
                datetype: action.payload.datetype.value
            }
        };
    }

    if (action.type === "SET_GLOBAL_DATE_RANGE_RAW") {
        return {
            ...state,
            compare_to: action.payload.compare_to,
            date_range: action.payload.date_range
        };
    }

    if (action.type === "SET_COMPARISON_DATES") {
        return {
            ...state,
            compare_to: action.payload.compare_to
        };
    }

    if (action.type === "SET_NORMALIZING_CURRENCY") {
        return {
            ...state,
            // Do not remember default currency in the currency log
            currency_seen: [...action.currency_seen],
            currency: action.currency,
            currency_decimals: action.currency_decimals,
            currency_symbol: action.currency_symbol,
            currency_source: action.currency_source
        };
    }

    if (action.type === "SET_MERCHANT_REF") {
        return {
            ...state,
            merchant_ref: action.merchant_ref === state.merchant_config.alias ? state.merchant_ref : action.merchant_ref,
            bu_ref: action.clear_bu_ref ? "" : state.bu_ref
        };
    }

    if (action.type === "SET_BU_REF") {
        return {
            ...state,
            bu_ref: action.bu_ref
        };
    }

    if (action.type === "SET_BU_AND_MERCHANT_REF") {
        return {
            ...state,
            merchant_ref: action.merchant_ref,
            bu_ref: action.bu_ref
        };
    }

    if (action.type === "SET_MERCHANT_CONFIG") {
        return {
            ...state,
            merchant_config: action.config || state.merchant_config
        };
    }

    if (action.type === "START_FETCHING_ADMINS") {
        return {
            ...state,
            admins: {
                ...state.admins,
                data: null,
                error: false,
                loading: true
            }
        };
    }

    if (action.type === "ERROR_FETCHING_ADMINS") {
        return {
            ...state,
            admins: {
                ...state.admins,
                data: null,
                loading: false,
                error: true
            }
        };
    }

    if (action.type === "SUCCESS_FETCHING_ADMINS") {
        return {
            ...state,
            admins: {
                ...state.admins,
                data: action.data,
                loading: false,
                error: false
            }
        };
    }

    if (action.type === "SET_ACTIVE_USER") {
        return {
            ...state,
            user: {
                ...state.user,
                ...action.data
            }
        };
    }

    if (action.type === "SET_AVAILABLE_MERCHANTS") {
        return {
            ...state,
            all_merchants: action.data
        };
    }

    if (action.type === "REQUESTS_SET_ACTIVE_PRODUCT") {
        return {
            ...state,
            product: action.data
        };
    }

    if (action.type === "SET_ACTIVE_ROUTE") {
        return {
            ...state,
            route: action.route
        };
    }

    if (action.type === "SET_ACTIVE_PAGE_NAME") {
        return {
            ...state,
            pageName: action.name
        };
    }

    if (action.type === "SET_ACTIVE_QUALIFIERS") {
        return {
            ...state,
            qualifiersStr: action.qualifiersStr
        };
    }

    if (action.type === "ADD_TO_HISTORY_STACK") {
        return {
            ...state,
            historyStack: [
                ...state.historyStack,
                action.data
            ]
        };
    }

    if (action.type === "CLEAR_HISTORY_STACK") {
        return {
            ...state,
            historyStack: defaultState.historyStack
        };
    }

    if (action.type === "LOAD_MERCHANT_USERS") {
        return {
            ...state,
            merchant_users: action.data || {}
        };
    }

    if (action.type === "UPDATE_MERCHANT_USER") {
        if (!action.id) throw new Error("Missing user id");
        return {
            ...state,
            merchant_users: {
                ...state.merchant_users,
                [action.id]: {
                    ...state.merchant_users[action.id],
                    ...action.data
                }
            }
        };
    }

    if (action.type === "STORE_NEEDY_MERCHANT_USERS_FOR_ADMIN") {
        return {
            ...state,
            users_needing_permissions: action.data || []
        };
    }

    if (action.type === "LOAD_MERCHANT_USER_GROUPS") {
        return {
            ...state,
            merchant_groups: action.data || []
        };
    }

    if (action.type === "SET_FILTERBAR_SRCDATA") {
        return {
            ...state,
            filterBarData: {
                ...state.filterBarData,
                all: action.data,
                builtForBU: action.bu
            }
        };
    }

    if (action.type === "GROUP_DELETE_SUCCESS") {
        return {
            ...state,
            merchant_groups: state.merchant_groups.filter((group) => group.id !== action.groupId)
        };
    }

    if (action.type === "GROUP_UPDATE_DEFAULT") {
        return {
            ...state,
            merchant_config: {
                ...state.merchant_config,
                default_group: action.groupId
            }
        };
    }

    if (action.type === "GROUP_CREATE_SUCCESS") {
        return {
            ...state,
            merchant_groups: [
                ...state.merchant_groups,
                action.data
            ]
        };
    }

    if (action.type === "GROUP_UPDATE_SUCCESS") {
        return {
            ...state,
            merchant_groups: state.merchant_groups.reduce((memo, group) => [
                ...memo,
                group.id === action.group.id ? action.group : group
            ], [])
        };
    }

    if (action.type === "SET_HAS_CHANGES") {
        return {
            ...state,
            has_unsaved_changes: action.has_unsaved_changes
        };
    }

    if (action.type === "USER_UPDATE_PROFILE_SUCCESS") {
        return {
            ...state,
            user: {
                ...state.user,
                ...action.data
            }
        };
    }

    if (action.type === "USER_SET_MERCHANT_CONFIG") {
        return {
            ...state,
            user_merchant_config: {
                ...action.data
            }
        };
    }

    if (action.type === "SET_USER_OPTINS") {
        return {
            ...state,
            user: {
                ...state.user,
                optIns: action.optIns
            }
        };
    }

    if (action.type === "SET_USER_FEE_REPORTS") {
        return {
            ...state,
            user: {
                ...state.user,
                optIns: {
                    ...state.user.optIns,
                    feeReports: action.feeReports
                }
            }
        };
    }
    
    if (action.type === "SET_FETCHED_PREREQUISITES") {
        return {
            ...state,
            user: {
                ...state.user,
                prerequisites: action.payload
            }
        };
    }
    
    if (action.type === "RESET_COMMON_STORE") {
        return {
            ...defaultState
        };
    }

    if (action.type === "SET_PAGE_QUALIFIER_TYPE") {
        return {
            ...state,
            pageQualifierType: action.pageQualifierType
        };
    }

    return state;
}
