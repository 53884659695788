export const pivots = {
    merchant_ref: "Merchant name",
    product: "Product",
    username: "Username",
    uri_template: "URI template",
    ip_address_country: "Request origin country",
    is_mimicked: "Mimicked",
    is_superuser: "Superuser",
    // eslint-disable-next-line sentence-case/sentence-case
    is_wp_user: "Worldpay RM",
    api_version: "API version"
};
