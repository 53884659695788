import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    view: "tablePivot"
};

const reducers = {
    STORE_ACTIVE_CARD: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            ...initialState,
            card: action.payload.card
        }
    }),

    STORE_ACTIVE_PIVOT_TABLE_STRUCTURE: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            ...initialState,
            pivot: action.payload.structure
        }
    }),

    STORE_ACTIVE_CARD_AND_PIVOT_TABLE_STRUCTURE: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            ...initialState,
            view: "tablePivot",
            card: action.payload.card,
            pivot: action.payload.structure
        }
    }),

    STORE_ACTIVE_CARD_AND_COT_PIVOT_TABLE_STRUCTURE: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            ...initialState,
            view: "tableInterval",
            card: action.payload.card,
            cot_pivot: action.payload.cot_pivot,
            cot_group: action.payload.cot_group,
            cot_view: action.payload.cot_view
        }
    }),

    STORE_ACTIVE_QUALIFIERS: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            qualifiers: action.payload.qualifiers
        }
    }),

    STORE_SHOW_TRANSACTIONS: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            show: action.payload.show
        }
    }),

    STORE_SET_INTERVAL: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            interval: {
                ...action.payload.interval
            }
        }
    }),

    STORE_SET_VIEW: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            view: action.payload.view
        }
    }),

    STORE_SET_FREQUENCY: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            freq: action.payload.freq
        }
    }),

    STORE_SET_TREND: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            trend: action.payload.trend
        }
    }),

    STORE_SET_METRICS: (state, action) => ({
        ...state,
        [action.payload.key]: {
            ...state[action.payload.key],
            metrics: action.payload.metrics
        }
    })

};

export const analytics_display_slice = createSlice({
    name: "analytics_display",
    reducers,
    initialState
});

export const analytics_display = analytics_display_slice.reducer;
export const {
    STORE_ACTIVE_QUALIFIERS,
    STORE_ACTIVE_CARD,
    STORE_ACTIVE_CARD_AND_PIVOT_TABLE_STRUCTURE,
    STORE_ACTIVE_CARD_AND_COT_PIVOT_TABLE_STRUCTURE,
    STORE_ACTIVE_PIVOT_TABLE_STRUCTURE,
    STORE_SHOW_TRANSACTIONS,
    STORE_SET_INTERVAL,
    STORE_SET_VIEW,
    STORE_SET_FREQUENCY,
    STORE_SET_TREND,
    STORE_SET_METRICS
} = analytics_display_slice.actions;
