// https://fkhadra.github.io/react-toastify/
import { Slide, toast as toastFn, ToastContainer as ToastContainerOriginal } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

export const toast = (message, options = {}) => toastFn(message, options);

["success", "error", "warning", "info"].forEach((type) => {
    const defaultOptions = {
        icon: false
    };
    
    if (type === "error") {
        defaultOptions.autoClose = false;
    }
    if (type === "warning") {
        defaultOptions.style = { backgroundColor: "#fff4da", color: "#614b1a" };
    }
    
    toast[type] = (message, options = {}) => toastFn[type](message, {
        ...defaultOptions,
        ...options
    });
});

toast.dismiss = toastFn.dismiss;
toast.TYPE = toastFn.TYPE;
toast.update = toastFn.update;

function CloseToast({ closeToast }) {
    return (
        <span className="toast-close-button" onClick={closeToast}>
            <FontAwesomeIcon icon={faTimes}/>
        </span>
    );
}

export function ToastContainer() {
    return (
        <ToastContainerOriginal
            position="top-center"
            transition={Slide}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            // pauseOnVisibilityChange
            theme="colored"
            draggable
            pauseOnHover
            closeButton={<CloseToast />}
        />
    );
}

export { useNotificationCenter } from "react-toastify/addons/use-notification-center";
