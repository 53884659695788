/* eslint-disable max-classes-per-file, lines-between-class-members */
export class PazienError extends Error {
    static defaultMessage = "";
    
    static sentryIgnore = false;
    
    constructor({
        message, originalError, tags = {}, extra = {}, ...rest
    } = {}) {
        const normalizedMessage = message || originalError?.message || PazienError.defaultMessage;
        
        super(normalizedMessage);
        
        this.message = normalizedMessage;
        this.name = this.constructor.name;
        
        this.tags = {
            ...originalError?.tags,
            ...tags
        };
    
        this.extra = {
            ...originalError?.extra,
            ...extra
        };
        
        this.custom = rest.custom || false; // Enable custom handling of the error in ErrorView
        this.header = rest.header; // Override default error header in ErrorView
        this.actions = rest.actions; // Don't provide any actions when shown in ErrorView
        this.customActions = rest.customActions; // List of custom actions to pass to ErrorView
        this.overrideUsername = rest.overrideUsername; // Adds ability to override username when captured in Sentry
        this.hideDetails = rest.hideDetails; // Hide error id details in ErrorView
        this.info = rest.info; // Extra message div below the original message
        this.preventGoBack = rest.preventGoBack; // Don't show go back in actions
        
        this.createStack(originalError);
    }
    
    createStack(originalError) {
        if (originalError?.stack) {
            Object.defineProperty(this, "stack", {
                enumerable: false,
                writable: false,
                value: originalError.stack
            });
        } else if (typeof Error.captureStackTrace === "function") { // V8 specific method
            this.stack = Error.captureStackTrace(this, this.constructor);
        } else {
            Object.defineProperty(this, "stack", {
                enumerable: false,
                writable: false,
                value: new Error(this.message).stack
            });
        }
    }
}

export class UnableToStartApp extends PazienError {
    name = "UnableToStartApp";
}

export class UnableFetchUserData extends PazienError {
    name = "UnableFetchUserData";
}

export class UserIsNotAssigned extends PazienError {
    name = "UserIsNotAssigned";
}

export class PasswordExpired extends PazienError {
    name = "PasswordExpired";

    constructor(props = {}) {
        super({
            ...props,
            message: "Your password has expired."
        });
        
        this.reset_token = props.reset_token;
    }
}

export class DisabledAccountLogin extends PazienError {
    name = "DisabledAccountLogin";
    
    static sentryIgnore = true;
}

export class SamlError extends PazienError {
    name = "SamlError";

    // https://github.com/Pazien//merchant-api/blob/develop/merchant_api/merchant/saml/saml.py#L40
    static errorsList = (displayName, userManagementName) => ({
        "1": `We're unable to sign you in${displayName ? ` from ${displayName}` : ""}, but we're looking into it.
                 Please${userManagementName ? ` return to ${userManagementName} and` : ""} try again.`,
        "2": "Your company does not have full Pazien access yet.\n"
             + "Contact your Worldpay relationship manager to request access.",
        "3": "Your company does not have access to Pazien.\n"
             + "Contact your Worldpay relationship manager to request access.",
        "4": "An admin is needed to review your account and grant access.",
        "5": <div>
            Access to Pazien via single sign on is not set up for your account.
            <div><a href="/merchant/login/provider/pazien">Sign in to your direct account</a> or contact your support manager to request access.</div>
            {/* eslint-disable-next-line react/jsx-closing-tag-location */}
        </div>,
        "6": "Unable to sign in due to invalid or missing permissions. An admin is needed to review your account."
    });
    
    static headers = {
        "4": "Your account is currently deactivated",
        "default": "Sorry"
    };
    
    constructor({
        error = 1, displayName, userManagementName, overrideUsername, ...rest
    }) {
        super({
            message: SamlError.errorsList(displayName, userManagementName)[error],
            header: SamlError.headers[error] || SamlError.errorsList.default,
            overrideUsername,
            ...rest,
            // Do not capture SAML errors from j.gerazounis@chargebacks911.com since the user is disabled,
            // but keeps hitting our UI with automated scraper
            captureInSentry: decodeURIComponent(overrideUsername) !== "j.gerazounis@chargebacks911.com"
        });
    }
}

export class NotImplementedError extends PazienError {
    name = "NotImplementedError";

    constructor(props = {}) {
        super({
            ...props,
            message: "Method must be implemented by a subclass"
        });
    }
}

export class EmptyTabLayoutError extends PazienError {
    name = "EmptyTabLayoutError";
}

export class NotSupportedBrowser extends PazienError {
    name = "NotSupportedBrowser";

    static sentryIgnore = true;
}

export class CannotConnectToAPI extends PazienError {
    name = "CannotConnectToAPI";
}

export class CannotConnectToAPIServerError extends PazienError {
    name = "CannotConnectToAPI";
}

export class MaintenanceMode extends PazienError {
    name = "MaintenanceMode";
}

export class UnknownAnalyticsSection extends PazienError {
    name = "UnknownAnalyticsSection";
}

export class UnableToAuthenticateSamlUser extends PazienError {
    name = "UnableToAuthenticateSamlUser";
}

export class ApiErrorFetchingData extends PazienError {
    name = "ApiErrorFetchingData";

    constructor(props = {}) {
        // Extract API endpoint from failed URL
        let culpritUrl;
    
        try {
            culpritUrl = props.url?.split("?")[0].replace(/[\da-f]{24}/gi, ":mongo_ref");
        } catch {
            culpritUrl = props.url;
        }
        
        super({
            ...props,
            message: `[${props.status}] ${culpritUrl}`
        });
        
        this.url = props.url;
        this.status = props.status;
    }
}

export class AbortError extends ApiErrorFetchingData {
    name = "AbortError";

    static sentryIgnore = true;
}

export class TypeErrorRequest extends ApiErrorFetchingData {
    name = "TypeErrorRequest";

    static sentryIgnore = true; // TypeError: Failed to fetch
}

export class ApiTimeoutFetchingData extends ApiErrorFetchingData {
    name = "ApiTimeoutFetchingData";
}

export class ApiDroppedRequest extends PazienError {
    name = "ApiDroppedRequest";

    static sentryIgnore = true;
}

export class DateRangeError extends PazienError {
    name = "DateRangeError";
}

export class UnspecifiedCurrency extends PazienError {
    name = "UnspecifiedCurrency";
}

export class UnknownBodyType extends PazienError {
    name = "UnknownBodyType";
}

export class UnableToFindMerchantRefInUserMerchants extends PazienError {
    name = "UnableToFindMerchantRefInUserMerchants";
}

export class NoViewPrivileges extends PazienError {
    name = "NoViewPrivileges";

    static sentryIgnore = true;
}

export class NoProviderData extends PazienError {
    name = "NoProviderData";

    static sentryIgnore = true;
    constructor() {
        super({ header: "No provider data", message: "Pazien has not received your provider data. Contact your service representative for setup and support." });
        this.custom = true;
        this.actions = false;
    }
}

export class UnableToLoadChunk extends PazienError {
    name = "UnableToLoadChunk";
}

export class MissingUserGroups extends PazienError {
    name = "MissingUserGroups";
}

export class ErrorUserManagement extends PazienError {
    name = "ErrorUserManagement";
}

export class QualifiersParsingError extends PazienError {
    name = "QualifiersParsingError";
}

export class QualifiersNotAllowedCategoryError extends PazienError {
    name = "QualifiersNotAllowedCategoryError";

    constructor(props) {
        props.message = `Filter "${props.extra.category}" is not allowed`;
        super(props);
        this.custom = true;
        this.customActions = [{
            className: "remove-filters",
            label: "Remove all filters"
        }];
    }
}

export class NoDataAvailable extends PazienError {
    name = "NoDataAvailable";
}

export class FetchErrorRetry extends PazienError {
    name = "FetchErrorRetry";
}

export class ErrorFetchingBusinessUnits extends PazienError {
    name = "ErrorFetchingBusinessUnits";

    constructor(props = {}) {
        const status = props.originalError?.status;
        let message = status === 403
            ? "You do not have permissions to edit business units"
            : "Error fetching business units";
        
        if (status === 403 && props.worldpay_rm) {
            message = "Access forbidden because the merchant has non-Worldpay data";
        }
        
        super({
            ...props,
            hideDetails: status === 403,
            message
        });
    }
}

export class ErrorFetchingReports extends PazienError {
    name = "ErrorFetchingReports";
}

export class ErrorFetchingAdminReport extends PazienError {
    name = "ErrorFetchingAdminReport";
}

export class ErrorFetchingTransactionsDetails extends PazienError {
    name = "ErrorFetchingTransactionsDetails";

    static sentryIgnore = true; // We don't need to capture this since can track in the request tool
}

export class ErrorMissingRequestedItem extends PazienError {
    name = "ErrorMissingRequestedItem";

    static sentryIgnore = true; // Admin UI error
}

export class ErrorNotAuthenticated extends PazienError {
    name = "ErrorNotAuthenticated";

    static sentryIgnore = true; // Ignore ErrorNotAuthenticated for now
}

export class ErrorReportNoEntries extends PazienError {
    name = "ErrorReportNoEntries";
}

export class ErrorMissingReportSchema extends PazienError {
    name = "ErrorMissingReportSchema";
}

export class ErrorFetchingAdmins extends PazienError {
    name = "ErrorFetchingAdmins";
}

export class ErrorFetchingConfig extends PazienError {
    name = "ErrorFetchingConfig";
}

export class ConfigError extends PazienError {
    name = "ConfigError";

    constructor() {
        super({
            message: "Config not found. Generate a config with `npm run configure`"
        });
    }
}

export class RequestError extends ApiErrorFetchingData {
    name = "RequestError";
}

export class InvalidGetterId extends PazienError {
    name = "InvalidGetterId";

    constructor(getterId) {
        super({
            extra: {
                getterId
            },
            message: "Getter ID not found"
        });
    }
}

export class MissingSnapshotError extends PazienError {
    name = "MissingSnapshotError";

    constructor(extra) {
        super({
            extra,
            actions: false,
            message: "There was an error generating this slide"
        });
    }
}

export class ErrorFetchingSnapshot extends PazienError {
    name = "ErrorFetchingSnapshot";

    constructor(error) {
        const messages = (status, title) => {
            if (status === 403) {
                if (title === "Data type restriction") {
                    return {
                        header: "Data type restriction",
                        message: "This report shows data that you do not have access to. If you think you should have access, contact your Admin, or your support representative to manage your user permissions."
                    };
                } if (title === "Business unit access denied") {
                    return {
                        header: "Business unit access denied",
                        message: "This report shows data from business units that you do not have access to. If you think you should have access, contact your Admin, or your support representative to manage your user permissions."
                    };
                } if (title === "Processor access denied") {
                    return {
                        header: "Provider access denied",
                        message: "This report shows data from provider(s) that you do not have access to. If you think you should have access, contact your Admin, or your support representative to manage your user permissions."
                    };
                }
                return {
                    header: "You’re missing permissions",
                    message: "This report shows data or business units that you do not have access to. If you think you should have access, contact your Admin, or your support representative to manage your user permissions."
                };
            } if (status === 404) {
                return {
                    header: "Report not found",
                    message: "Check your report link."
                };
            } if (status === 500) {
                return {
                    header: "Unable to load report right now",
                    message: "There was an internal error loading this report. Please close the report and come back later. If the issue continues, contact your support representative."
                };
            }
            return {
                header: "Error",
                message: "There was an error fetching this report."
            };
        };
        
        const shouldCapture = error.response?.status === 500;
        const { header, message } = messages(error.response?.status, error.extra?.message?.title);
        
        super({
            header,
            originalError: error,
            actions: false,
            captureInSentry: shouldCapture,
            hideDetails: !shouldCapture,
            message
        });
    }
}
