import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    psd2_fraud: {},
    fraudsight: {},
    threedsflex: {},
    cashflow: {},
    all_fraud: {},
    fees: {},
    chargebacks: {},
    settlements: {},
    authorizations: {},
    declines: {}
};

const reducers = {
    SET_SECTION_OVERRIDES: (state, action) => ({
        ...state,
        [action.payload.section]: {
            ...state[action.payload.section],
            overrides: action.payload.overrides
        }
    }),
    SET_SELECTED_TAB: (state, action) => ({
        ...state,
        [action.payload.section]: {
            ...state[action.payload.section],
            tab: action.payload.tab
        }
    }),
    SET_SELECTED_ROWS: (state, action) => ({
        ...state,
        [action.payload.section]: {
            ...state[action.payload.section],
            [action.payload.tab]: {
                selectedRows: action.payload.selectedRows
            }
        }
    })
};

export const analytics_Slice = createSlice({
    name: "analytics",
    initialState,
    reducers
});

export const analytics = analytics_Slice.reducer;
export const {
    SET_SELECTED_TAB,
    SET_SELECTED_ROWS,
    SET_SECTION_OVERRIDES
} = analytics_Slice.actions;
