/* global APP_VERSION */
import * as Sentry from "@sentry/react"; // Sentry library
import { Integrations } from "@sentry/tracing";
import { history } from "common/components/history";
import * as errors from "common/helpers/errors";

import { store } from "common/store";

import { AbortError, ApiErrorFetchingData } from "common/helpers/errors";

let installed = false;

const getCommonDataProps = () => ({
    history: store.getState().common.historyStack
});

export const setUserContext = (user = store.getState().common.user) => {
    Sentry.configureScope((scope) => {
        scope.setUser({
            username: user.username,
            email: user.username,
            first_name: user.first_name,
            last_name: user.last_name,
            sso_user: user.sso_user,
            admin: user.admin,
            superuser: user.superuser,
            merchant_ref: user.merchant_ref,
            user_ref: user._id, // TODO: deprecate user_ref
            mimic: store.getState().authentication.mimic_as_user !== null,
            original_user: store.getState().authentication.mimic_as_user
        });
    });
};

export const captureException = function(error, { tags = {}, extra = {}, captureRequestError = false } = {}) {
    if (error instanceof AbortError
        || (!captureRequestError && error instanceof ApiErrorFetchingData)
    ) return false;
    
    if (installed) {
        if (error.overrideUsername) {
            Sentry.configureScope((scope) => {
                scope.setUser({
                    username: error.overrideUsername
                });
            });
        }
    
        Sentry.withScope((scope) => {
            scope.setExtras({
                ...error.extra,
                ...extra
            });
            
            scope.setTags({
                ...error.tags,
                ...tags
            });
            Sentry.captureException(error);
        });
    } else {
        console.error(error);
    }
    
    return true;
};

export const initSentry = function(auth, options = {}) {
    const state = store.getState();
    if (state.config.env === "local" && !state.settings.run_sentry_localhost) return false;
    
    Sentry.init({
        dsn: `https://${auth}@sentry.io/149293`,
        autoSessionTracking: true,
        maxUrlLength: 12_500,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                tracingOrigins: [ // We don't need to track requests to local api
                    /api.pazien.com(?!.*version)/,
                    /api.stg1.pazien.com(?!.*version)/
                ]
            })
        ],
        tracesSampleRate: 0.25,
        ignoreErrors: Object.values(errors).filter((e) => e.sentryIgnore),
        release: APP_VERSION,
        beforeBreadcrumb(breadcrumb, hint) {
            if (breadcrumb.category === "ui.click") {
                try {
                    breadcrumb.data = {
                        ...breadcrumb.data,
                        innerText: hint.event.target.innerText.split("\n", 2).join("\n") // Send only first two lines
                    };
                } catch {
                    // Noop
                }
            }
            
            return breadcrumb;
        },
        beforeSend(event, hint) {
            // Wrapping beforeSend into try/catch to prevent infinite loop like
            // https://sentry.io/organizations/pazien/issues/2338320587/?project=149293
            
            try {
                // Add extra from the exception instance
                event.extra = {
                    ...getCommonDataProps(),
                    ...event.extra || hint.originalException?.extra
                };
            
                // Add custom tags or grab tags from the exception instance
                event.tags = {
                    ...event.tags || hint.originalException?.tags,
                    silent: event.tags?.response_status === 0 ? 1 : 0,
                    product: store.getState().common.product
                };
            
                // Customize some events
                // Group ChunkLoadError more aggressively
                if (hint.originalException?.name === "ChunkLoadError") {
                    event.fingerprint = [
                        hint.originalException.name,
                        hint.originalException.type
                    ];
                }
    
                // Group errors with status 0 more aggressively
                if (hint.originalException?.response?.status === 0) {
                    event.fingerprint = [
                        hint.originalException.name
                    ];
                }
    
                return event;
            } catch (error) {
                console.error(hint.originalException);
                console.error(error);
                
                // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-beforesend
                return null;
            }
        },
        ...options
    });
    installed = true;
    return true;
};

export const setTagsContext = function(tags) {
    Sentry.configureScope((scope) => scope.setTags(tags));
};

export const captureMessage = function(message, { level = "info", tags = {}, extra = {} } = {}) {
    Sentry.withScope((scope) => {
        scope.setExtras({
            ...getCommonDataProps(),
            ...extra
        });
        scope.setTags(tags);
        Sentry.captureMessage(message, level);
    });
};

export const captureBreadcrumb = function({ message, category = "general", data = {} } = {}) {
    // We should remain silent if there is an error in errorHandler
    try {
        Sentry.addBreadcrumb({
            message,
            category,
            data
        });
    } catch {
        // noop
    }
};

export const lastEventId = () => Sentry.lastEventId();

export const getFeedback = function() {
    captureException(new Error("Custom error reporting"));
    
    const { first_name, last_name, username } = store.getState().common.user;
    
    Sentry.showReportDialog({
        eventId: Sentry.lastEventId(),
        user: {
            name: `${first_name} ${last_name}`,
            email: username
        }
    });
};
