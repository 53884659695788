import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptions: {
        loading: false,
        loaded: false,
        error: false,
        share_error: false,
        data: [],
        needSecondaryLookup: false,
        sharedRule: null
    },
    active: ["inbox"]
};

const reducers = {
    ALERTS_SET_ACTIVE_TAB: (state, action) => ({
        ...state,
        active: [action.tab],
        subscriptions: {
            ...state.subscriptions,
            share_error: false,
            sharedRule: null
        }
    }),

    ALERTS_SET_FROM_URL_PARAMS: (state, action) => ({
        ...state,
        active: [
            action.data.context || state.active[0],
            action.data.id
        ]
    }),

    EDIT_SUBSCRIPTION_SUCCESS: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            data: state.subscriptions.data.map((s) => {
                if (s._id === action.subscription._id) {
                    return action.subscription;
                }
                return s;
            })
        }
    }),

    REVERT_RULE_CHANGES: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            data: state.subscriptions.data.map((s) => {
                if (s._id === action.originalRule._id) {
                    return action.originalRule;
                }
                return s;
            })
        }
    }),

    CREATE_SUBSCRIPTION_SUCCESS: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            data: [...state.subscriptions.data, action.data[0]],
            count: action.count
        }
    }),

    CLONE_SUBSCRIPTION_SUCCESS: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            data: [...state.subscriptions.data, action.data[0]],
            count: action.count
        },
        active: [
            "subscriptions",
            action.data[0]._id,
            true
        ]
    }),

    EDIT_LIBRARY_RULE_SUCCESS: (state, action) => ({
        ...state,
        library: {
            ...state.library,
            data: state.library.data.map((rule) => {
                if (rule._id === action.rule._id) {
                    return action.rule;
                }
                return rule;
            })
        }
    }),

    CREATE_LIBRARY_RULE_SUCCESS: (state, action) => ({
        ...state,
        library: {
            ...state.library,
            data: [...state.library.data, action.data]
        }
    }),

    SUBSCRIPTIONS_FETCH_START: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            loading: true,
            loaded: false,
            error: false
        }
    }),

    SUBSCRIPTIONS_FETCH_SUCCESS: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            loading: false,
            loaded: !action.sharedLookup,
            error: false,
            data: action.data,
            count: action.count,
            limit: action.limit,
            sharedRule: false
        }
    }),

    SUBSCRIPTIONS_FETCH_ERROR: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            loading: false,
            loaded: true,
            error: true
        }
    }),

    SHARED_SUBSCRIPTION_FETCH_ERROR: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            loading: false,
            loaded: true,
            share_error: true,
            sharedRule: true
        }
    }),

    SHARED_SUBSCRIPTION_FETCH_SUCCESS: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            loading: false,
            loaded: true,
            sharedRule: action.data
        }
    }),

    SHOW_CREATE_NEW_FORM: (state, action) => ({
        ...state,
        active: [
            action.context,
            "new"
        ]
    }),

    SHOW_EDIT_FORM: (state, action) => ({
        ...state,
        active: [
            action.context,
            action.id,
            action.isClone
        ]
    }),

    LIBRARY_FETCH_START: (state, action) => ({
        ...state,
        library: {
            ...state.library,
            loading: true,
            loaded: false,
            error: false
        }
    }),

    LIBRARY_FETCH_SUCCESS: (state, action) => ({
        ...state,
        library: {
            ...state.library,
            loading: false,
            loaded: true,
            error: false,
            data: action.data
        }
    }),

    LIBRARY_FETCH_ERROR: (state, action) => ({
        ...state,
        library: {
            ...state.library,
            loading: false,
            loaded: true,
            error: true
        }
    }),

    DELETE_SUBSCRIPTION_SUCCESS: (state, action) => ({
        ...state,
        subscriptions: {
            ...state.subscriptions,
            data: state.subscriptions.data.filter(({ _id }) => _id !== action.id), // Filter out deleted rule
            count: action.count
        }
    }),

    DELETE_LIBRARY_RULE_SUCCESS: (state, action) => ({
        ...state,
        library: {
            ...state.library,
            data: state.library.data.filter(({ _id }) => _id !== action.id) // Filter out deleted rule
        }
    })
};

export const alertsSlice = createSlice({
    name: "alerts",
    initialState,
    reducers
});

export const alerts = alertsSlice.reducer;
export const actions = alertsSlice.actions;
