import { pivots } from "administrator/features/requests/pivots";

const defaultState = {
    qualifiers: "",
    section: "merchant_ref",
    typeahead: {},
    defaultTabs: [
        "merchant_ref",
        "uri_template",
        "username",
        "product",
        "api_version"
    ].map((pivot) => ({
        id: pivot,
        label: pivots[pivot],
        pivots: [pivot]
    }))
};

export function requests(state = defaultState, action) {
    if (action.type === "REQUESTS_UPDATE_FILTERS") {
        return {
            ...state,
            qualifiers: action.qualifiers
        };
    }
    
    if (action.type === "UPDATE_REQUESTS_ACTIVE_TAB") {
        return {
            ...state,
            section: action.section
        };
    }
    
    if (action.type === "REQUESTS_SET_STATE_FROM_URL") {
        return {
            ...state,
            qualifiers: action.data.qualifiers || state.qualifiers || defaultState.qualifiers,
            section: action.data.section || state.section || defaultState.section
        };
    }
    
    if (action.type === "TOGGLE_REQUEST_DETAILS") {
        return {
            ...state,
            section: state.section === "details" ? defaultState.section : "details"
        };
    }
    
    if (action.type === "REQUESTS_SET_TYPEAHEAD_DATA") {
        return {
            ...state,
            typeahead: action.data
        };
    }
    
    return {
        ...state
    };
}
